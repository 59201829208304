import Flickity from 'flickity';
import 'imagesloaded';

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    const $nav = $('.js-nav-mobile');
    const $burgerButton = $('.js-burger');

    const animateHeader = () => {
      $burgerButton.toggleClass('active');
      $nav.addClass('transition');
      $nav.toggleClass('active');
      $('html').toggleClass('noscroll');
    };

    $('.js-slide__content').imagesLoaded(
      {
        background: true,
      },
      function () {
        $('.js-slide__content').addClass('active');
        $('.js-loading').addClass('active');
      }
    );
    if ($burgerButton.length > 0) {
      $burgerButton.on('click', function () {
        animateHeader();
      });
    }

    if ($('.js-share__item__action').length > 0) {
      $('.js-share__item__action').on('click', function (e) {
        e.preventDefault();
        window.open($(this).attr('href'), 'title', 'width=600, height=500');
      });
    }

    if ($('.js-textcarousel').length > 0) {
      const $flexibleCarousel = new Flickity('.js-textcarousel', {
        prevNextButtons: false,
        pageDots: true,
        imagesLoaded: true,
        adaptiveHeight: true,
      });
      $flexibleCarousel.on('select', function () {});
    }

    if ($('.js-homeHero').length > 0) {
      const $heroFlkty = new Flickity('.js-homeHero', {
        prevNextButtons: false,
        pageDots: false,
        imagesLoaded: true,
        autoPlay: 5000,
      });

      $('.js-homeHero__carousel__action').on('click', function (e) {
        e.preventDefault();
        const index = $(this).data('jump');
        $heroFlkty.select(index);
      });

      $heroFlkty.on('select', function () {
        let currentIndex = $heroFlkty.selectedIndex;
        currentIndex += 1;
        $('.js-homeHero__carousel__nav__item').removeClass('active');
        $(
          `.js-homeHero__carousel__nav__item:nth-child(${currentIndex})`
        ).addClass('active');
      });
    }
  },
};
