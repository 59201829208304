/* Author: Agile Pixel */
/* on READY */

const inputValidataor = function ($inputs) {
  if ($inputs.length > 0) {
    $inputs.each(function () {
      if ($(this).find('input, textarea').val() === "") {
        $(this).removeClass('active');
      } else {
        $(this).addClass('active');
      }
    });
  }
}
jQuery(document).ready(function ($) {
  'use strict';
  //define variables for all objects for re-use throughout scripts
  const $input = $('.js-input__text, .js-input__textarea');
  /**
   *
   * Input box class naming switch for styling
   *
   */

  if ($input.length > 0) {
    $('.main').on('focus, click, focusin', 'input, textarea', function () {
      $(this).closest('.js-input__text, .js-input__textarea').addClass('active');
    }).on('blur', 'input, textarea', function () {
      if ($(this).val() === "") {
        $(this).closest('.js-input__text, .js-input__textarea').removeClass('active');
      }
    });
    $input.each(function () {
      if ($(this).find('input, textarea').val() === "") {
        $(this).removeClass('active');
      } else {
        $(this).addClass('active');
      }
    });
  }
});


jQuery(document).bind('gform_post_render', function () {
  inputValidataor($('.js-input__text, .js-input__textarea'));
});
